import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const Home = lazy(() => import("../pages/Home"));
const Notfound = lazy(() => import("../pages/Notfound"));

export default function Routes() {
  return (
    <Router>
      <Suspense fallback={<p>Loading...</p>}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Redirect
                to={{
                  pathname: "/dragonhotpot",
                }}
              />
            )}
          />
          <Route exact path="/:id" component={Home} />
          <Route exact path="/pages/notfound" component={Notfound} />
        </Switch>
      </Suspense>
    </Router>
  );
}
